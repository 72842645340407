import { Col, Row } from 'antd';
import {
  getVideoProgressInPercentage,
  secondsToMinute
} from '../../../../common/utils';
import VideoGridLoading from '../../../skeleton/VideoGridLoading';
import SectionHeader from '../../SectionHeader/SectionHeader';
import VideoCard from './VideoCard';

function VideoGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  actionButton,
  showVideoProgressBar,
  videosWatchTimes
}) {
  return (
    <section className="video-grid">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={!actionButton && headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 16, lg: 16 },
            { xs: 16, sm: 16, md: 32, lg: 32 }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
                <VideoCard
                  src={item?.src}
                  videoThumbnail={item?.videoThumbnail}
                  blurhash={item?.blurhash}
                  defaultValue={item?.defaultValue}
                  time={secondsToMinute(item?.time)}
                  category={item?.category}
                  categoryHref={item?.categoryHref}
                  collection={item?.collection}
                  title={item?.title}
                  date={item?.date}
                  tags={item?.tags}
                  tagBackground={item?.tagBackground}
                  tagTextColor={item?.tagTextColor}
                  href={item?.href}
                  {...(showVideoProgressBar && {
                    defaultValue: getVideoProgressInPercentage(
                      videosWatchTimes?.[item?.id],
                      item?.time
                    )
                  })}
                  isPremium={item?.isPremium}
                  instanceData={item?.instanceData}
                  instanceId={item?.instanceId}
                  instanceType={item?.instanceType}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

VideoGrid.Loading = function Loading() {
  return <VideoGridLoading />;
};

export default VideoGrid;
